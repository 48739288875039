import { GenericUserService } from '@/services';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import FtpUser from '@/models/user/FtpUser';

const ftpUserService = new GenericUserService('sftp-users');

const state = {
  defaultAll: {
    data: [],
    meta: {
      pagination: {
        count: 0,
        current_page: 1,
        per_page: 10,
        total: 0,
        total_pages: 1,
      },
    },
  },
  all: {
    data: [],
    meta: {
      pagination: {
        count: 0,
        current_page: 1,
        per_page: 10,
        total: 0,
        total_pages: 1,
      },
    },
  },
  allSaved: {
    data: [],
    meta: {
      pagination: {
        count: 0,
        current_page: 1,
        per_page: 10,
        total: 0,
        total_pages: 1,
      },
    },
  },
  currentFtpUser: new FtpUser(),
  savedFtpUser: new FtpUser(),
  formattedCurrentFtpUser: null,
  formSendFtpUser: {},
  loading: false,
};

const getters = {
  couldSaveOne: (state) => {
    let validTitle = state.currentFtpUser?.title?.length > 0;
    let validKeys = true;
    state.currentFtpUser.keys.forEach((key) => {
      validKeys =
        validKeys && key.value.startsWith('ssh-rsa ') && key.value.length >= 10;
    });
    return validTitle && validKeys;
  },
  shouldSaveOne: (state) => {
    return hasDifference(state.currentFtpUser, state.savedFtpUser);
  },
};

const actions = {
  resetAll({ commit, dispatch }) {
    commit('cleanAllStates');
  },
  resetOne({ commit, dispatch }) {
    commit('cleanOneStates');
  },
  async getAll({ commit, dispatch }) {
    commit('getAllRequest');
    return ftpUserService.getAll().then(
      (ftpUsers) => {
        commit('getAllSuccess', ftpUsers);
        return Promise.resolve(ftpUsers);
      },
      (error) => {
        commit('cleanAllStates', error);
        return Promise.reject(error);
      }
    );
  },
  async get({ commit, dispatch }, id) {
    commit('getRequest');
    return ftpUserService.get(id).then(
      (ftpUser) => {
        commit('getSuccess', ftpUser);
        return Promise.resolve(ftpUser);
      },
      (error) => {
        commit('cleanAllStates', error);
        return Promise.reject(error);
      }
    );
  },
  async create({ commit, dispatch }, ftpUser) {
    commit('createRequest', ftpUser);
    return ftpUserService.create(state.formattedCurrentFtpUser).then(
      (ftpUser) => {
        commit('createSuccess', ftpUser);
        const alert = {
          id: 'ftp-user-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(ftpUser);
      },
      (error) => {
        commit('createFailure');
        const alert = {
          id: 'ftp-user-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  async update({ commit, dispatch }) {
    commit('updateRequest');
    return ftpUserService
      .update(state.formattedCurrentFtpUser, state.currentFtpUser.id)
      .then(
        (ftpUser) => {
          commit('updateSuccess', ftpUser);
          const alert = {
            id: 'ftp-user-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(ftpUser);
        },
        (error) => {
          commit('updateFailure');
          const alert = {
            id: 'ftp-user-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  async delete({ commit, dispatch }, id) {
    commit('deleteRequest');
    return ftpUserService.delete(id).then(
      (ftpUser) => {
        commit('deleteSuccess', id);
        const alert = {
          id: 'ftp-user-well-deleted',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        commit('cleanAllStates', error);
        const alert = {
          id: 'ftp-user-not-deleted',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  async search({ commit, dispatch }, { page, keyword, sort, facets }) {
    commit('searchRequest');
    let queryString =
      'count=10&page=' +
      page +
      '&sort=' +
      (typeof sort === 'undefined' ? 'date:desc' : sort);
    if (keyword.length > 0) {
      queryString += '&query=' + keyword;
    }
    if (facets.length > 0) {
      let filters = [];
      facets.forEach((facet) => {
        if (typeof filters[facet.facet_id] === 'undefined') {
          filters[facet.facet_id] = [];
        }
        filters[facet.facet_id].push(facet.id);
      });
      Object.keys(filters).forEach(function (key) {
        queryString +=
          '&' +
          key +
          '=' +
          (filters[key].length > 1
            ? 'in:' + filters[key].join(',')
            : filters[key][0]);
      });
    }
    return ftpUserService.search('', queryString).then(
      (ftpUsers) => {
        commit('searchSuccess', ftpUsers);
        return Promise.resolve(ftpUsers);
      },
      (error) => {
        commit('searchFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  cleanAllStates(state) {
    state.loading = false;
    state.all = cloneDeep(state.defaultAll);
    state.allSaved = cloneDeep(state.defaultAll);
  },
  cleanOneStates(state) {
    state.loading = false;
    state.currentFtpUser = new FtpUser();
    state.savedFtpUser = new FtpUser();
    state.formattedCurrentFtpUser = null;
  },

  getAllRequest(state) {
    state.loading = true;
    state.all = cloneDeep(state.defaultAll);
    state.allSaved = cloneDeep(state.defaultAll);
  },
  getAllSuccess(state, ftpUsers) {
    ftpUsers.forEach((x) => {
      return new FtpUser(x);
    });
    state.all = ftpUsers;
    state.allSaved = ftpUsers;
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = cloneDeep(state.defaultAll);
    state.allSaved = cloneDeep(state.defaultAll);
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
    state.currentFtpUser = new FtpUser();
    state.savedFtpUser = new FtpUser();
  },
  getSuccess(state, ftpUser) {
    state.currentFtpUser = new FtpUser(ftpUser);
    state.savedFtpUser = new FtpUser(ftpUser);
    state.loading = false;
  },
  getFailure(state) {
    state.currentFtpUser = new FtpUser();
    state.savedFtpUser = new FtpUser();
    state.loading = false;
  },

  createRequest(state) {
    state.loading = true;
    state.formattedCurrentFtpUser = cloneDeep(state.currentFtpUser);
    state.formattedCurrentFtpUser.path =
      state.formattedCurrentFtpUser.client_path;
  },
  createSuccess(state, input) {
    state.currentFtpUser = new FtpUser(input);
    state.savedFtpUser = new FtpUser(input);
    state.all = cloneDeep(state.defaultAll);
    state.allSaved = cloneDeep(state.defaultAll);
    state.loading = false;
  },
  createFailure(state, input) {
    state.formattedCurrentFtpUser = null;
    state.loading = false;
  },

  updateRequest(state) {
    state.loading = true;
    state.formattedCurrentFtpUser = cloneDeep(state.currentFtpUser);
    state.formattedCurrentFtpUser.path =
      state.formattedCurrentFtpUser.client_path;
    delete state.formattedCurrentFtpUser.type;
    delete state.formattedCurrentFtpUser.user_name;
  },
  updateSuccess(state, input) {
    state.formattedCurrentFtpUser = null;
    state.currentFtpUser = new FtpUser(input);
    state.savedFtpUser = new FtpUser(input);
    state.loading = false;
  },
  updateFailure(state) {
    state.formattedCurrentFtpUser = null;
    state.loading = false;
  },

  deleteRequest(state) {
    state.loading = true;
    state.currentFtpUser = new FtpUser();
    state.savedFtpUser = new FtpUser();
  },
  deleteSuccess(state, id) {
    state.currentFtpUser = new FtpUser();
    state.savedFtpUser = new FtpUser();
    state.loading = false;
  },
  deleteFailure(state) {
    state.currentFtpUser = new FtpUser();
    state.savedFtpUser = new FtpUser();
    state.loading = false;
  },

  searchRequest(state) {
    state.loading = true;
    state.all = cloneDeep(state.defaultAll);
    state.allSaved = cloneDeep(state.defaultAll);
  },
  searchSuccess(state, ftpUsers) {
    let datasBrut = cloneDeep(ftpUsers?.data ?? []);
    let datasClean = [];
    datasBrut.forEach((dataBrut) => {
      datasClean.push(new FtpUser(dataBrut));
    });
    ftpUsers.data = datasClean;
    state.all = ftpUsers;
    state.allSaved = cloneDeep(ftpUsers);
    state.loading = false;
  },
  searchFailure(state) {
    state.all = cloneDeep(state.defaultAll);
    state.allSaved = cloneDeep(state.defaultAll);
    state.loading = false;
  },

  setCurrentFtpUserById(state, ftpUserId) {
    let ftpUsers = cloneDeep(state.all.data);
    let ftpUser = ftpUsers.find((x) => x.id === ftpUserId);
    state.currentFtpUser = ftpUser;
    state.savedFtpUser = cloneDeep(ftpUser);
  },
};

export const ftpUsers = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
