<template>
  <div class="flex flex-col">
    <BaseButton
      nude
      class="!px-0"
      size="xxs"
      font-weight="normal"
      text-align="left"
      @click="$emit('toggle')"
    >
      <Icon icon="audio" size="md" />
      <span class="text-xs">{{
        $tc('videos.audiotrack.count', audios.length)
      }}</span>
      <Icon
        icon="caret-down"
        class="transform transition-transform ease-in-out mr-0"
        size="xs"
        :class="{ '-rotate-180': show }"
      />
    </BaseButton>

    <ul v-if="show">
      <li
        class="first:mt-8 mt-4"
        :disabled="!canEditAudio"
      >
        <BaseButton
          nude
          class="!px-0"
          font-weight="normal"
          size="xxs"
          icon-before="add"
          @click="showModalAddAudio()"
          :disabled="!canEditAudio"
        >
          <Icon icon="add" size="sm" />
          <span class="text-xs font-weight-medium">{{ $t('videos.audios.button.add') }}</span>
        </BaseButton>
      </li>
      <li
        v-for="(audio, i) in audios"
        :key="i"
        class="first:mt-8 otto-base-ui-component size-xs flex items-center pl-6 first:mt-2"
      >
        <span v-if="!audio.editable" class="inline-flex break-all line-clamp-1 text-xs">{{ getLanguageName(audio.locale) }}</span>
        <Tag v-else size="xs" type="filled" weight="normal" tabindex="-1" :themed="true" variant="gray" :disabled="! canEditAudio">
          <span class="inline-flex break-all line-clamp-1 text-xs">
            <!--{{ audio.url }}-->
            {{ getLanguageName(audio.locale) }}
          </span>
          <div
            class="cursor-pointer"
            @click="removeThisAudio(audio)"
            :disabled="!canEditAudio"
          >
            <Icon icon="close" size="xs" />
          </div>
        </Tag>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { cloneDeep, isEmpty} from 'lodash';
import { useStore } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import Tag from '@/components/tag/Tag.vue';

const store = useStore();

const props = defineProps({
  videoType: String,
  videoData: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  show: {
    type: Boolean,
    default: false,
  },
  partnerId: {
    type: String,
    default: null,
  },
});

const audios = computed(() => {
  const rawMainAudios = props.videoData?.audiotracks ?? [];
  const rawAdditionalAudios = props.videoData?.audios ?? [];

  let audios = [];

  rawMainAudios.forEach((rawMainAudio) => {
    audios.push({
      id: null,
      url: 'undefined',
      locale: rawMainAudio,
      editable: false
    });
  });

  rawAdditionalAudios.forEach((rawAdditionalAudio) => {
    audios.push({
      id: rawAdditionalAudio.id ?? null,
      url: rawAdditionalAudio.url ?? 'undefined',
      locale: rawAdditionalAudio.locale ?? 'und',
      editable: true
    });
  });
  return audios;
});

const canEditAudio = computed(() => {
  return props.type === 'program' &&
    !['betv', 'molotov', 'otto', 'free', null].includes(props.partnerId) &&
    ( 
      props.partnerId !== 'amazon' ||
      props.videoType !== 'trailer'
    );
});

const allLanguages = computed(() => {
  return store.getters['languages/groupedAllLanguages'];
});

function getLanguageName(iso) {
  const temp = allLanguages.value.filter((x) => x.iso_639_3 === iso);
  return temp?.length > 0
    ? temp[0].title
    : iso;
};

function removeThisAudio(audio) {
  if (props.type === 'video') {
    removeCurrentNewVideoAudio(audio.locale);
  } else {
    let label = isEmpty(props.partnerId) ? 'video' : `${props.partnerId}_video` ;
    removeCurrentNewMovieAudio({
      label: label,
      videoType: props.videoData.type,
      videoId: props.videoData.id,
      locale: audio.locale
    });
  }
};

function showModalAddAudio() {
  const videoData = cloneDeep(props.videoData);

  let usedLocales = { default: audios.value.map((audio) => audio.locale) };

  const modalParams = {
    videoData: videoData,
    assetType: 'audio',
    type: 'program',
    programId: videoData.program_id,
    videoId: videoData.id,
    videoType: videoData.type,
    partnerId: props.partnerId,
    usedLocales: usedLocales,
  };
  const modalName = 'ModalAddVideoAssetFromFtp';

  showModal({
    componentName: modalName,
    componentParams: modalParams,
    size: 'md',
  });
};

const removeCurrentNewVideoAudio = (payload) => {
  store.dispatch('videos/removeAudio', payload);
};

const removeCurrentNewMovieAudio = (payload) => {
  store.commit('programs/removeMovieAudio', payload);
};

const showModal = (payload) => {
  store.commit('showModal', payload);
};

</script>
