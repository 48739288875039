/*
 * @prettier
 */
import { GenericContentService } from '@/services';
import { SelectionTransformer } from '@/transformers';
import { cloneDeep, union } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import { picturesTypes } from '@/data/pictures';
import Selection from '@/models/Selection';
import utils from '@/utils/utils';

const state = {
  loading: false,
  all: [],
  allSaved: [],
  formatedCurrentNewSelection: {},
  currentNewSelection: {},
  savedNewSelection: {},
};

const getters = {
  selectionStatus: (state) => {
    var apiStatus = state.currentNewSelection?.status;
    var hasPicture = state.currentNewSelection?.picture?.banners?.length > 0;
    var validTitle = state.currentNewSelection?.title?.length > 0;
    var hasProgram = state.currentNewSelection?.programs?.length > 0;

    if (!hasPicture || !validTitle || !hasProgram) {
      return 'incomplete';
    } else {
      switch (apiStatus) {
        case 'incomplete':
          return 'ready';
        default:
          return apiStatus;
      }
    }
  },
  selectionErrors: (state) => {
    var list = [];
    if (state.currentNewSelection?.title?.length === 0) {
      list.push({
        type: 'title',
        message: 'error.selection.title',
      });
    }
    if (state.currentNewSelection?.picture?.banners?.length === 0) {
      list.push({
        type: 'banner',
        message: 'error.selection.banner',
      });
    }
    if (state.currentNewSelection?.programs?.length === 0) {
      list.push({
        type: 'program',
        message: 'error.selection.program',
      });
    }
    return list;
  },
  shouldSaveAll: (state) => {
    var diff = 0;
    if (typeof state.all.data !== 'undefined') {
      state.all.data.forEach(function (currentOne) {
        var savedIndex = state.allSaved.data.findIndex(
          (x) => x.id === currentOne.id
        );
        if (savedIndex >= 0) {
          var savedOne = state.allSaved.data[savedIndex];
          if (hasDifference(savedOne, currentOne)) {
            diff++;
          }
        } else {
          diff++;
        }
      });
      state.allSaved.data.forEach(function (savedOne) {
        var currentIndex = state.all.data.findIndex(
          (x) => x.id === savedOne.id
        );
        if (currentIndex >= 0) {
          var currentOne = state.all.data[currentIndex];
          if (hasDifference(currentOne, savedOne)) {
            diff++;
          }
        } else {
          diff++;
        }
      });
      return diff > 0;
    } else {
      return false;
    }
  },
};

const actions = {
  getAll({ commit, dispatch }, queryString) {
    commit('getAllRequest');
    return new GenericContentService('selections').getAll(queryString).then(
      (selections) => {
        commit('getAllSuccess', selections);
        return Promise.resolve(selections);
      },
      (error) => {
        commit('cleanProgramStates', error);
        return Promise.reject(error);
      }
    );
  },

  get({ commit, dispatch }, { id, isNew }) {
    commit('getRequest');
    return new GenericContentService('selections').get(id).then(
      (selection) => {
        commit('getSuccess', selection);
        return Promise.resolve(selection);
      },
      (error) => {
        commit('getFailure');
        return Promise.reject(error);
      }
    );
  },

  create({ commit, dispatch }, selection) {
    commit('postRequest');
    return new GenericContentService('selections').create(selection).then(
      (selections) => {
        commit('postSuccess', selections);
        const alert = {
          id: 'selection-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(selections);
      },
      (error) => {
        commit('postFailure');
        const alert = {
          id: 'selection-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  update({ commit, dispatch, rootGetters }) {
    var name = cloneDeep(state.currentNewSelection.style.block_type_name);
    const blockTypeId = rootGetters['references/block_type_id'](
      name === null ? 'row-posters' : name
    );
    commit('putRequest', blockTypeId);
    return new GenericContentService('selections')
      .update(state.formatedCurrentNewSelection, state.currentNewSelection.id)
      .then(
        (selection) => {
          commit('putSuccess', selection);
          const alert = {
            id: 'selection-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(selection);
        },
        (error) => {
          commit('putFailure', false);
          const alert = {
            id: 'selection-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  search({ commit, dispatch }, [needle, order]) {
    commit('searchRequest');
    return new GenericContentService('selections').search(needle, order).then(
      (selections) => {
        commit('searchSuccess', selections);
        return Promise.resolve(selections);
      },
      (error) => {
        commit('searchFailure', error);
        return Promise.reject(error);
      }
    );
  },

  saveState({ commit }) {
    commit('saveState');
  },

  publish({ commit, dispatch, rootGetters }) {
    var name = cloneDeep(state.currentNewSelection.style.block_type_name);
    const blockTypeId = rootGetters['references/block_type_id'](
      name === null ? 'row-posters' : name
    );
    commit('publishRequest', blockTypeId);
    return new GenericContentService('selections')
      .update(
        state.formatedCurrentNewSelection,
        state.formatedCurrentNewSelection.id
      )
      .then(
        (selection) => {
          commit('publishSuccess', selection);
          const alert = {
            id: 'selection-well-published',
            icon: 'check',
            type: 'valid',
            message: 'notifications.publish.online.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(selection);
        },
        (error) => {
          commit('publishFailure', false);
          const alert = {
            id: 'selection-not-published',
            icon: 'close',
            type: 'error',
            message: 'notifications.publish.online.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  unpublish({ commit, dispatch, rootGetters }) {
    var name = cloneDeep(state.currentNewSelection.style.block_type_name);
    const blockTypeId = rootGetters['references/block_type_id'](
      name === null ? 'row-posters' : name
    );
    commit('unpublishRequest', blockTypeId);
    return new GenericContentService('selections')
      .update(
        state.formatedCurrentNewSelection,
        state.formatedCurrentNewSelection.id
      )
      .then(
        (selection) => {
          commit('unpublishSuccess', selection);
          const alert = {
            id: 'selection-well-unpublished',
            icon: 'check',
            type: 'valid',
            message: 'notifications.publish.offline.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(selection);
        },
        (error) => {
          commit('unpublishFailure', false);
          const alert = {
            id: 'selection-not-unpublished',
            icon: 'close',
            type: 'error',
            message: 'notifications.publish.offline.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  deleteSelection({ commit, dispatch }, selectionId) {
    commit('deleteRequest');
    return new GenericContentService('selections')
      .delete(state.currentNewSelection.id)
      .then(
        (selection) => {
          commit('deleteSuccess', selection);
          const alert = {
            id: 'selection-well-deleted',
            icon: 'check',
            type: 'valid',
            message: 'notifications.delete.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve();
        },
        () => {
          commit('deleteFailure', false);
          const alert = {
            id: 'selection-not-deleted',
            icon: 'close',
            type: 'error',
            message: 'notifications.delete.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject();
        }
      );
  },
};

const mutations = {
  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, selections) {
    state.all = selections;
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.allSaved = [];
    state.loading = false;
  },

  getRequest() {
    state.loading = true;
  },
  getSuccess(state, payload) {
    var selection = new Selection(payload);
    state.currentNewSelection = cloneDeep(selection);
    state.savedNewSelection = cloneDeep(selection);
    state.formatedCurrentNewSelection = {};
    state.loading = false;
  },
  getFailure(state) {
    state.currentNewSelection = {};
    state.savedNewSelection = {};
    state.formatedCurrentNewSelection = {};
    state.loading = false;
  },

  postRequest() {
    state.loading = true;
  },
  postSuccess(state, payload) {
    var selection = new Selection(payload);
    state.currentNewSelection = cloneDeep(selection);
    state.savedNewSelection = cloneDeep(selection);
    state.loading = false;
  },
  postFailure(state) {
    state.currentNewSelection = {};
    state.savedNewSelection = {};
    state.formatedCurrentNewSelection = {};
    state.loading = false;
  },

  putRequest(state, blockTypeId) {
    state.loading = true;
    state.formatedCurrentNewSelection = cloneDeep(state.currentNewSelection);
    let transformer = new SelectionTransformer(
      state.formatedCurrentNewSelection,
      blockTypeId
    );
    state.formatedCurrentNewSelection = transformer.transform();
  },
  putSuccess(state, payload) {
    var selection = new Selection(payload);
    state.currentNewSelection = cloneDeep(selection);
    state.savedNewSelection = cloneDeep(selection);
    state.loading = false;
  },
  putFailure() {
    state.loading = false;
  },

  searchRequest(state) {
    state.loading = true;
    state.all = [];
    state.allSaved = [];
  },
  searchSuccess(state, selections) {
    var datasBrut = cloneDeep(selections?.data ?? []);
    var datasClean = [];
    datasBrut.forEach((dataBrut) => {
      datasClean.push(new Selection(dataBrut));
    });
    selections.data = datasClean;
    state.all = selections;
    state.allSaved = cloneDeep(selections);
    state.loading = false;
  },
  searchFailure(state) {
    state.all = [];
    state.allSaved = [];
    state.loading = false;
  },

  publishRequest(state, blockTypeId) {
    state.loading = true;
    state.currentNewSelection.status = 'online';
    state.formatedCurrentNewSelection = cloneDeep(state.currentNewSelection);
    state.formatedCurrentNewSelection = utils.formatData(
      state.formatedCurrentNewSelection
    );
    let transformer = new SelectionTransformer(
      state.formatedCurrentNewSelection,
      blockTypeId
    );
    state.formatedCurrentNewSelection = transformer.transform();
  },
  publishSuccess(state, payload) {
    var selection = new Selection(payload);
    state.currentNewSelection = cloneDeep(selection);
    state.savedNewSelection = cloneDeep(selection);
    if ('data' in state.all) {
      state.all.data.forEach(function (selection, key) {
        if (selection.id === state.currentNewSelection.id) {
          state.all.data[key].status = 'online';
        }
      });
    }
    state.loading = false;
  },
  publishFailure() {
    state.loading = false;
  },

  unpublishRequest(state, blockTypeId) {
    state.loading = true;
    state.currentNewSelection.status = 'offline';
    state.formatedCurrentNewSelection = cloneDeep(state.currentNewSelection);
    let transformer = new SelectionTransformer(
      state.formatedCurrentNewSelection,
      blockTypeId
    );
    state.formatedCurrentNewSelection = transformer.transform();
  },
  unpublishSuccess(state, payload) {
    var selection = new Selection(payload);
    state.currentNewSelection = cloneDeep(selection);
    state.savedNewSelection = cloneDeep(selection);
    if ('data' in state.all) {
      state.all.data.forEach(function (selection, key) {
        if (selection.id === state.currentNewSelection.id) {
          state.all.data[key].status = 'offline';
        }
      });
    }
    state.loading = false;
  },
  unpublishFailure() {
    state.loading = false;
  },

  deleteRequest(state) {
    state.loading = true;
  },
  deleteSuccess(state) {
    if (state.all.data) {
      state.all.data.forEach(function (selection, key) {
        if (selection.id === state.currentNewSelection.id) {
          state.all.data.splice(key, 1);
        }
      });
    }
    state.currentNewSelection = {};
    state.savedNewSelection = {};
    state.formatedCurrentNewSelection = {};
    state.loading = false;
  },
  deleteFailure() {
    state.loading = false;
  },

  syncSelectionRank(state) {
    var selections = cloneDeep(state.all.data);
    for (var i = 0; i < selections.length; i++) {
      var selection = selections[i];
      selection.rank = i;
      state.all.data.splice(i, 1, selection);
    }
  },
  updateSelectionRank(state, element) {
    var selections = cloneDeep(state.all.data);
    var oldIndex = selections.findIndex((x) => x.id === element.id);
    var newIndex = 0;
    if (element.rank <= 1) {
      newIndex = 0;
    } else if (element.rank >= selections.length - 1) {
      newIndex = selections.length - 1;
    } else {
      newIndex = element.rank - 1;
    }
    var selection = selections[oldIndex];
    selection.rank = element.rank;
    state.all.data.splice(newIndex, 0, state.all.data.splice(oldIndex, 1)[0]);
  },
  toggleSelectionCatalogState(state, id) {
    var index = state.all.data.findIndex((x) => x.id === id);
    var selection = cloneDeep(state.all.data[index]);
    selection.display_catalog = !selection.display_catalog;
    state.all.data.splice(index, 1, selection);
  },

  saveState(state) {
    state.allSaved = cloneDeep(state.all);
  },

  setCurrentNewSelection(state, payload) {
    state.currentNewSelection = cloneDeep(payload);
  },
  addPrograms(state, payload) {
    for (var i = 0; i < payload.length; i++) {
      state.currentNewSelection.programs.push(payload[i]);
    }
  },
  updateCurrentNewSelectionImage(state, payload) {
    let image = {
      key: payload.key,
      url: payload.url,
      custom_properties: payload.custom_properties,
      collectionName: payload.collectionName,
    };
    if (typeof payload.id !== 'undefined') {
      image.id = payload.id; //update image with id;
    }
    if (payload.collectionName in state.currentNewSelection.picture) {
      state.currentNewSelection.picture[payload.collectionName].splice(0, 1);
    } else {
      state.currentNewSelection.picture[payload.collectionName] = [];
    }
    state.currentNewSelection.picture[payload.collectionName] = [image];
  },
  removeCurrentNewSelectionImage(state, image) {
    if (image && image.collectionName in state.currentNewSelection.picture) {
      const currentType = image.collectionName;
      state.currentNewSelection.picture[currentType].splice(0, 1);
      const currentTypeSettings = picturesTypes.find(
        (selectionCollectionType) =>
          selectionCollectionType.collectionName === currentType
      );
      if (currentTypeSettings?.declinations) {
        currentTypeSettings.declinations.forEach((declination) => {
          if (declination in state.currentNewSelection.picture) {
            state.currentNewSelection.picture[declination].splice(0, 1);
          }
        });
      }
    }
  },

  cleanSelectionStates(state) {
    state.all = [];
    state.allSaved = [];
    state.currentNewSelection = {};
    state.savedNewSelection = {};
    state.formatedCurrentNewSelection = {};
    state.loading = false;
  },
};

export const selections = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
