<template>
  <div v-if="breadcrumbList" class="mb-2">
    <ul v-if="$isDesktop" class="flex">
      <li
        v-for="(breadcrumb, i) in breadcrumbList"
        :key="i"
        class="items-center text-xs mr-2 font-light after:ml-2 after:content-['>'] last:mr-0 last:after:hidden hidden lg:flex last:flex last:before:content-['<'] lg:last:before:content-none"
      >
        <span
          v-if="
            isCurrentPage(breadcrumb) || typeof breadcrumb.path === 'undefined'
          "
        >
          {{ getBreadcrumbName(breadcrumb?.name) }}
        </span>
        <router-link
          v-else
          :to="getBreadcrumbPath(breadcrumb)"
          :class="
            breadcrumbList.length - 1 !== i && 'text-teal-500 && font-medium'
          "
        >
          {{ getBreadcrumbName(breadcrumb?.name) }}
        </router-link>
      </li>
    </ul>
    <div v-else class="flex">
      <router-link
        v-if="previousPage"
        :to="getBreadcrumbPath(previousPage)"
        class="items-center text-teal-500 && font-medium text-xs font-light before:mr-2 before:content-['<']"
      >
        {{ getBreadcrumbName(previousPage.name) }}
      </router-link>
    </div>
  </div>
  <div v-else class="h-4 mb-2" />
</template>

<script>

export default {
  name: 'Breadcrumb',
  inject: ['$isDesktop'],
  props: [
    'discountCampaignId',
    'emailName',
    'legalName',
    'metadataDetailId',
    'metadataDetailTitle',
    'optionId',
    'optionTitle',
    'movieTitle',
    'episodeTitle',
    'parentMovieTitle',
    'parentMovieId',
    'selectionTitle',
    'seasonTitle',
    'seasonId',
    'title',
    'userEmail',
    'groupPlanName',
    'planName',
  ],
  data() {
    return {
      currentRoute: this.$route.path,
      sticky: {
        type: Boolean,
        default: false,
      },
    };
  },
  computed: {
    breadcrumbList() {
      if (
        this.movieTitle &&
        this.parentMovieTitle &&
        this.parentMovieId &&
        this.seasonId
      ) {
        return this.$route.meta.breadcrumb.season;
      } else if (this.movieTitle) {
        return this.$route.meta.breadcrumb.default;
      } else {
        return this.$route.meta.breadcrumb;
      }
    },
    previousPage() {
      return this.breadcrumbList
        ? this.breadcrumbList[this.breadcrumbList.length - 2]
        : null;
    },
  },
  methods: {
    isCurrentPage(breadcrumb) {
      return this.getBreadcrumbPath(breadcrumb) === this.$route.path;
    },
    getBreadcrumbName(name) {
      if (!name) return '';
      switch (name) {
        case ':emailName':
        case ':legalName':
        case ':selectionName':
        case ':optionTitle':
        case ':userEmail':
        case ':ftpUserTitle':
          return this.title;
        case ':movieName':
          return this.movieTitle;
        case ':episodeName':
          return this.episodeTitle;
        case ':seasonName':
          return this.seasonTitle;
        case ':parentMovieName':
          return this.parentMovieTitle;
        case ':metadataDetailTitle':
        case ':title':
        case ':groupPlanName':
          return this[name.substring(1)];
        case ':planName':
          return this.planName;
        default:
          return this.$t(name);
      }
    },
    getBreadcrumbPath(breadcrumb) {
      let path = breadcrumb.path;
      if (path) {
        const pathParts = path.split('/');
        const dynamicPathParts = pathParts.map((part) => {
          let clear = part.substring(1);
          if (
            part.startsWith(':') &&
            typeof this.$route.params[clear] !== 'undefined'
          ) {
            return this.$route.params[clear];
          }
          if (part === ':parentMovieId') {
            return this.parentMovieId;
          }
          if (part === ':seasonId') {
            return this.seasonId;
          }
          return part;
        });
        return dynamicPathParts.join('/');
      } else {
        return '';
      }
    },
  },
};
</script>
